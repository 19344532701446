// gatsby-browser.tsx and gatsby-ssr.tsx
import './src/styles/global.scss';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { WrapRootElementBrowserArgs, WrapRootElementNodeArgs } from 'gatsby';
import i18n from './src/locales/i18nSetup';

export const wrapRootElement = ({ element }: WrapRootElementBrowserArgs | WrapRootElementNodeArgs) => (
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>{element}</I18nextProvider>
  </React.StrictMode>
);
