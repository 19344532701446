// i18nSetup.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import nlTranslation from './nl.json';
import frTranslation from './fr.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      nl: { translation: nlTranslation },
      fr: { translation: frTranslation },
    },
    lng: 'nl', // Default language
    fallbackLng: 'nl', // Fallback language
    interpolation: {
      escapeValue: false, // Not needed for React
    },
  })
  .then()
  .catch((error) => {
    console.error('i18n initialization error:', error);
  });

export default i18n;
